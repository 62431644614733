import { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { MSmallButton } from "../common/mobile-styled";
import { RootUserState } from "../redux/store";
import {
  reqBlogLikeByUser,
  reqPlusViewCount,
  reqToggleBlogLike,
  reqViewNotice,
} from "../requests/user";
import CustomerPageTitle from "./CustomerPageTitle";
import Icon from "./Icon";

const CMobileNotice = () => {
  const user = useSelector((state: RootUserState) => state.user);
  const { noticeId } = useParams();
  const [notice, setNotice] = useState<any>();
  const [userLiked, setUserLiked] = useState<boolean>();

  // 현재 블로그 글 정보 불러오기
  async function getSelectedNotice() {
    const res = await reqViewNotice(Number(noticeId));
    setNotice(res.data.notice);
  }

  // 읽음 처리
  async function plusViewCount() {
    await reqPlusViewCount({ id: noticeId });
  }

  // 현재 블로그 글이 사용자가 좋아요를 눌렀는지 불러오기
  async function getBlogLikeByUser() {
    const res = await reqBlogLikeByUser({ userId: user.id, blogId: noticeId });
    setUserLiked(res.data);
  }

  useEffect(() => {
    getSelectedNotice();
    plusViewCount();
    getBlogLikeByUser();
  }, []);

  const toggleLikeButton = async () => {
    await reqToggleBlogLike({ userId: user.id, blogId: noticeId });
    await getSelectedNotice();
    setUserLiked(!userLiked);
  };

  return (
    <div style={{ padding: "1.4rem" }}>
      <CustomerPageTitle subtitle1="Event" title="Notice" />

      {notice && (
        <MobileWrapper>
          <Col>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Title:</Label>
              <P>{notice.title}</P>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Subtitle:</Label>
              <P>{notice.subtitle}</P>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Category:</Label>
              <P>{notice.category}</P>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>Updated At:</Label>
              <P>
                {new Date(notice.updatedAt).toLocaleDateString("ko-KR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hourCycle: "h23",
                })}
              </P>
            </div>

            <div style={{ display: "flex", flexDirection: "row" }}>
              <Label>View:</Label>
              <P>{notice.view + 1 || "0"}</P>
            </div>

            {notice.category === "Event" && (
              <div className="f-row" style={{ alignItems: "center" }}>
                <P>
                  <MSmallButton
                    className="f-row"
                    style={{
                      border: "1px solid var(--color-black)",
                      padding: ".4rem .6rem",
                    }}
                    onClick={() => toggleLikeButton()}
                  >
                    <div
                      style={{
                        borderRight: "1px solid var(--color-black)",
                        padding: "0 .6rem 0 0",
                        margin: "0 .6rem 0 0",
                      }}
                    >
                      {userLiked ? (
                        <Icon
                          icon="suit-heart-fill"
                          style={{ marginRight: ".4rem" }}
                          color="var(--color-pink)"
                        />
                      ) : (
                        <Icon
                          icon="suit-heart"
                          style={{ marginRight: ".4rem" }}
                        />
                      )}
                      Like
                    </div>
                    {notice.like}
                  </MSmallButton>
                </P>
              </div>
            )}
          </Col>

          <div style={{ marginTop: "1.4rem" }}>
            <>
              {notice.imageUrl && (
                <img
                  src={`https://kr.object.ncloudstorage.com/ontact-bucket/${notice.imageUrl}`}
                  alt="notice"
                  style={{
                    width: isMobile ? "80vw" : "40rem",
                    marginTop: "0.6rem",
                    marginBottom: "1.6rem",
                  }}
                />
              )}
            </>
          </div>
          <div>
            <div dangerouslySetInnerHTML={{ __html: notice.content }}></div>
          </div>
        </MobileWrapper>
      )}
    </div>
  );
};

export const MobileWrapper = styled.div`
  margin: 0 0 8rem 0;

  @media (max-width: 768px) {
    margin-bottom: 4rem;
  }
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0.6rem 0.4rem 0;
`;
export const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const NoticeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 2rem 0rem;
  padding: 0 2rem 0 0;
  width: 100%;
`;
const NoticeHeader = styled.div`
  display: flex;
  justify-content: flex-end;
  border-bottom: 2px solid #dee2e6;
  & > div {
    display: flex;
    flex-direction: row;
    label {
      font-weight: bold;
      margin: 0 0.5rem 0 1rem;
    }
  }
`;
const NoticeTitle = styled.div`
  text-align: center;
  padding: 2rem 0;
  font-size: 1.3rem;
  background-color: #f8f9fa;
`;
const NoticeContents = styled.div`
  padding: 3rem 1.5rem;
  & > div > img {
    max-width: 30rem;
    max-height: 30rem;
    object-fit: scale-down;
    margin-bottom: 2rem;
  }
`;
const NoticeListBackButtonRow = styled.div`
  align-items: end;
  width: 100%;
  & > div {
    text-align: end;
    button {
      background-color: gray;
      color: white;
    }
  }
`;

export default CMobileNotice;
