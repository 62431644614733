import { useEffect, useState } from "react";
import { Button, FormControl, InputGroup, Table } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Swal from "sweetalert2";
import { ADMIN_BADGE, USER_BADGE } from "../../../common/constants";
import {
  MButton,
  MCard,
  MDeleteButton,
  MItem,
  MPageTitle,
  MRow,
  MTwoButtonRow,
  MWrapper,
} from "../../../common/mobile-styled";
import CustomerPageTitle from "../../../components/CustomerPageTitle";
import Icon from "../../../components/Icon";
import { RootUserState } from "../../../redux/store";
import { reqAddAdminBadge, reqReadBadge } from "../../../requests/badge";
import { reqlost } from "../../../requests/ship";
import { reqlostGetUser } from "../../../requests/warehouse";

interface UserRequest {
  orderNumber: string;
  site: string;
  status: string;
  createdAt: string;
  userId: string;
}

const CLost = () => {
  const user = useSelector((state: RootUserState) => state.user);
  const [orders, setOrders] = useState([
    {
      userId: user.id,
      orderNumber: "",
      site: "",
      productName: "",
      quantity: "",
      file: null,
    },
  ]);
  const [userRequests, setUserRequests] = useState<UserRequest[]>([]);
  const [visibleCount, setVisibleCount] = useState(10); // Initial visible count for requests
  const addOrder = () => {
    setOrders([
      ...orders,
      {
        userId: user.id,
        orderNumber: "",
        site: "",
        productName: "",
        quantity: "",
        file: null,
      },
    ]);
  };

  useEffect(() => {
    const fetchUserRequests = async () => {
      try {
        const response = await reqlostGetUser({ userId: user.id });
        setUserRequests(response.data);
      } catch (error) {
        console.error("Failed to fetch user requests:", error);
      }
    };

    fetchUserRequests();

    const badgePayload = {
      userId: user.id,
      badgeName: USER_BADGE.LOST_PRODUCT,
    };
    reqReadBadge(badgePayload);
  }, [user.id]);

  const handleInputChange = (index: number, event: any) => {
    const { name, value, files } = event.target;
    const updatedOrders = [...orders];

    if (name === "file") {
      updatedOrders[index] = { ...updatedOrders[index], file: files[0] };
    } else {
      updatedOrders[index] = { ...updatedOrders[index], [name]: value };
    }
    setOrders(updatedOrders);
  };

  const handleDeleteOrder = (index: number) => {
    const newOrders = [...orders];
    newOrders.splice(index, 1);
    setOrders(newOrders);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    if (isSubmitting) {
      return;
    }

    setIsSubmitting(true);

    if (user.Check) {
      await Swal.fire({
        title: "Action Blocked",
        text: "Please wait until the space code changes.",
        icon: "error",
        confirmButtonText: "OK",
      });
      setIsSubmitting(false);
      return;
    }

    let isAllFieldsFilled = true;

    for (const order of orders) {
      if (
        !order.orderNumber.trim() ||
        !order.site.trim() ||
        !order.productName.trim() ||
        !order.quantity.toString().trim()
      ) {
        Swal.fire({
          icon: "error",
          title: "Incomplete Form",
          text: "All fields including order number, site, product name, and quantity are required and cannot be blank.",
          confirmButtonText: "OK",
        });
        isAllFieldsFilled = false;
        break;
      }

      const formData = new FormData();
      formData.append("userId", user.id);
      formData.append("orderNumber", order.orderNumber.trim());
      formData.append("site", order.site.trim());
      formData.append("productName", order.productName.trim());
      formData.append("quantity", order.quantity.toString().trim());

      setIsLoading(true);
      if (order.file) {
        formData.append("file", order.file);
      }

      if (isAllFieldsFilled) {
        try {
          await reqlost(formData);

          const badgePayload = {
            badgeName: ADMIN_BADGE.LOST_PRODUCT,
          };
          await reqAddAdminBadge(badgePayload);

          setIsLoading(false);

          Swal.fire({
            icon: "success",
            title: "Request Submitted",
            text: "Your request for loss has been completed.",
            confirmButtonText: "OK",
          });
        } catch (error) {
          console.error("Error:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: "An error occurred while processing your request.",
            confirmButtonText: "OK",
          });
          isAllFieldsFilled = false;
          break;
        }
      }
    }

    setIsSubmitting(false);

    if (isAllFieldsFilled) {
      window.location.reload();
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  const [searchWord, setSearchWord] = useState<string>("");
  const [filteredOrders, setFilteredOrders] = useState<any[]>([]);

  useEffect(() => {
    const filteredData = userRequests.filter((request: any) =>
      request.orderNumber.toLowerCase().includes(searchWord.toLowerCase())
    );
    setFilteredOrders(filteredData);
  }, [userRequests, searchWord]);

  const renderOrders = (order: any, index: number) => (
    <MItem key={index} style={{ paddingBottom: "1rem" }}>
      <div>
        <div className="input-label">Tracking Number</div>
        <div>
          <input
            type="text"
            className="form-control"
            name="orderNumber"
            value={order.orderNumber.toUpperCase()}
            onKeyPress={handleKeyPress}
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>
      <div>
        <div className="input-label">Site</div>
        <div>
          <input
            type="text"
            className="form-control"
            name="site"
            value={order.site}
            onKeyPress={handleKeyPress}
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>
      <div>
        <div className="input-label">Product Name</div>
        <div>
          <input
            type="text"
            className="form-control"
            name="productName"
            value={order.productName}
            onKeyPress={handleKeyPress}
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>
      <div>
        <div className="input-label">Quantity</div>
        <div>
          <input
            type="number"
            className="form-control"
            name="quantity"
            value={order.quantity}
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>
      <div>
        <div className="input-label">Product Image</div>
        <div>
          <input
            className="form-control"
            type="file"
            id="formFile"
            name="file"
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      </div>
      <div>
        <MDeleteButton
          style={{
            backgroundColor: "var(--color-main-red)",
            color: "var(--color-white)",
            marginTop: "1rem",
          }}
          onClick={() => handleDeleteOrder(index)}
        >
          Delete
        </MDeleteButton>
      </div>
    </MItem>
  );

  const renderRequests = (request: any, index: number) => {
    return (
      <>
        {isMobile ? (
          <MItem key={index}>
            <MRow>
              <div>Order No.</div>
              <div>{request.orderNumber}</div>
            </MRow>
            <MRow>
              <div>Request At</div>
              <div>
                {new Date(request.createdAt).toLocaleDateString("ko-KR", {
                  year: "numeric",
                  month: "2-digit",
                  day: "2-digit",
                  hour: "2-digit",
                  minute: "2-digit",
                  hourCycle: "h23",
                })}
              </div>
            </MRow>
            <MRow>
              <div>Status</div>
              <div>{request.status}</div>
            </MRow>
          </MItem>
        ) : (
          <tr key={index}>
            <td>{request.orderNumber}</td>
            <td>
              {new Date(request.createdAt).toLocaleDateString("ko-KR", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hourCycle: "h23",
              })}
            </td>
            <td>{request.status}</td>
          </tr>
        )}
      </>
    );
  };

  const loadMoreRequests = () => {
    setVisibleCount((prevCount) => prevCount + 10);
  };

  return (
    <>
      {isMobile ? (
        <MWrapper style={{ padding: "1.4rem" }}>
          {isLoading ? (
            <LoadingSection>
              <img src="/resources/img/loading.gif" alt="로딩중" />
            </LoadingSection>
          ) : null}
          <div>
            <CustomerPageTitle title="Find My Tracking Number" />

            <div>
              <InputGroup className="mb-3" style={{ width: "100%" }}>
                <FormControl
                  className=""
                  placeholder="Order Number"
                  value={searchWord.toUpperCase()}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                />
                <Button
                  variant="outline-success"
                  style={{ borderColor: "var(--color-main-blue)" }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </Button>
              </InputGroup>
            </div>

            <MCard>
              <MPageTitle
                style={{
                  borderBottom: "1px solid lightgray",
                  margin: "0",
                  paddingBottom: "1rem",
                }}
              >
                Create Request
              </MPageTitle>
              <div>{orders.map(renderOrders)}</div>
              <MTwoButtonRow>
                <MButton
                  style={{
                    backgroundColor: "var(--color-main-blue)",
                    color: "var(--color-white)",
                    marginTop: "1rem",
                  }}
                  onClick={addOrder}
                >
                  Add Product
                </MButton>
                <MButton
                  style={{
                    backgroundColor: "var(--color-main-blue)",
                    color: "var(--color-white)",
                    marginTop: "1rem",
                  }}
                  onClick={handleSubmit}
                >
                  Send
                </MButton>
              </MTwoButtonRow>
            </MCard>

            <div>
              <MPageTitle>Requested History</MPageTitle>
              <div
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  marginBottom: "4rem",
                }}
              >
                {filteredOrders
                  .filter(
                    (request) =>
                      request.userId === user.id &&
                      (request.status === "Not Done" ||
                        request.status === "CAN NOT TRACK")
                  )
                  .slice(0, visibleCount)
                  .map(renderRequests)}
                {visibleCount < filteredOrders.length && (
                  <MButton
                    style={{
                      backgroundColor: "var(--color-main-blue)",
                      color: "var(--color-white)",
                      marginTop: "1rem",
                      width: "100%",
                    }}
                    onClick={loadMoreRequests}
                  >
                    Load More
                  </MButton>
                )}
              </div>
            </div>
          </div>
        </MWrapper>
      ) : (
        <PCWrapper style={{ padding: "1.4rem" }}>
          {isLoading ? (
            <LoadingSection>
              <img src="/resources/img/loading.gif" alt="로딩중" />
            </LoadingSection>
          ) : null}
          <div>
            <CustomerPageTitle title="Find My Tracking Number" />

            <div style={{ display: "flex", justifyContent: "right" }}>
              <InputGroup className="mb-3" style={{ width: "300px" }}>
                <FormControl
                  className=""
                  placeholder="Order Number"
                  value={searchWord.toUpperCase()}
                  onKeyPress={handleKeyPress}
                  onChange={(e) => {
                    setSearchWord(e.target.value);
                  }}
                />
                <Button
                  variant="outline-success"
                  style={{ borderColor: "var(--color-main-blue)" }}
                >
                  <Icon icon="search" color="var(--color-main-blue)" />
                </Button>
              </InputGroup>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <MCard style={{ width: "47%" }}>
                <MPageTitle
                  style={{
                    borderBottom: "1px solid lightgray",
                    margin: "0",
                    paddingBottom: "1rem",
                  }}
                >
                  Create Request
                </MPageTitle>
                <div>{orders.map(renderOrders)}</div>
                <MTwoButtonRow>
                  <MButton
                    style={{
                      backgroundColor: "var(--color-main-blue)",
                      color: "var(--color-white)",
                      marginTop: "1rem",
                    }}
                    onClick={addOrder}
                  >
                    Add Product
                  </MButton>
                  <MButton
                    style={{
                      backgroundColor: "var(--color-main-blue)",
                      color: "var(--color-white)",
                      marginTop: "1rem",
                    }}
                    onClick={handleSubmit}
                  >
                    Send
                  </MButton>
                </MTwoButtonRow>
              </MCard>

              <MCard style={{ width: "49%" }}>
                <MPageTitle
                  style={{
                    borderBottom: "1px solid lightgray",
                    margin: "0",
                    paddingBottom: "1rem",
                  }}
                >
                  Requested History
                </MPageTitle>
                <TableContainer>
                  <Table>
                    <thead>
                      <tr>
                        <th>Order No.</th>
                        <th>Request At</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredOrders
                        .filter(
                          (request) =>
                            request.userId === user.id &&
                            (request.status === "Not Done" ||
                              request.status === "CAN NOT TRACK")
                        )
                        .map(renderRequests)}
                    </tbody>
                  </Table>
                </TableContainer>
              </MCard>
            </div>
          </div>
        </PCWrapper>
      )}
    </>
  );
};

export default CLost;

const TableContainer = styled.div`
  max-height: 500px;
  overflow-y: auto;
`;

const LoadingSection = styled.div`
  position: absolute;
  z-index: 1000;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  & > img {
    width: 100%;
  }
`;

const PCWrapper = styled.div`
  margin: 0;
  padding: 1rem;
`;

const MBodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: center;
  align-items: center;
`;
const Col = styled.div`
  display: flex;
  flex-direction: column;
`;

const Label = styled.label`
  font-weight: bold;
  margin: 0.4rem 0 0.4rem 0;
`;
const P = styled.p`
  margin: 0.4rem 0 0.4rem 0;
`;

const MainContainer = styled.main`
  padding-top: 30px;
  padding-left: 30px;
`;

const SectionContainer = styled.section`
  width: 100%;
  max-width: 1300px;
  padding: 0;

  @media (min-width: 768px) {
  }
`;
const CellBase = styled.p`
  text-align: center;
  margin: 0;
  padding: 1rem;
  align-items: center;
  flex: 1;
`;
const NoticeTableBodyRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-bottom: 1px solid #dee2e6;
  &:last-child {
    border-bottom: none;
  }
`;
const HeadCell = styled(CellBase)`
  font-weight: bold;
`;
const BodyCell = styled(CellBase)`
  flex: 1;
`;
const HeadCellTitle = styled(HeadCell)`
  flex: 4;
`;
const NoticeTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 2rem 0rem;
`;
const NoticeTableHead = styled.div`
  display: flex;
  background-color: #f8f9fa;
  padding: 5px;
  border-bottom: 2px solid #dee2e6;
`;
const NoticeTableBody = styled.div`
  display: flex;
  flex-direction: column;
`;
