import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ADMIN_BADGE, CODE_QNA_STATUS } from "../../common/constants";
import { formatDate } from "../../common/format";
import {
  MButton,
  MCard,
  MItem,
  MPageTitle,
  MRow,
  MSmallButton,
} from "../../common/mobile-styled";
import CustomerPageTitle from "../../components/CustomerPageTitle";
import { RootUserState } from "../../redux/store";
import { reqAddAdminBadge } from "../../requests/badge";
import { reqUploadQNAImage } from "../../requests/code";
import {
  reqCreateQuestionAndResponse,
  reqQuestionAndResponseByUserId,
} from "../../requests/user";
interface QuestionAndResponse {
  userId: string;
  questionContent: string;
  responseContent?: string;
}

interface ModalWrapperProps {
  isOpen: boolean;
}

interface Response {
  id: number;
  title: string;
  userType: boolean;
  categoryId: number;
  questionContent: string;
  createdAt: string;
  responseAt?: string;
  responseContent: string;
  status: number;
  email: string;
  userId: string;
  imageUrl?: string;
}

const CSupportForm = () => {
  const nav = useNavigate();

  const [category, setCategory] = useState("");
  const [responses, setResponses] = useState<Response[]>([]);
  const [question, setQuestion] = useState("");
  const [title, setTitle] = useState("");
  const [userType, setUserType] = useState(false);
  const [email, setEmail] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState<Response | null>(
    null
  );
  const [dropdownText, setDropdownText] = useState<string>("Choice");
  const [imageFile, setImageFile] = useState<File | null>(null);
  const user = useSelector((state: RootUserState) => state.user);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    const fetchResponses = async () => {
      try {
        const fetchedResponses = await reqQuestionAndResponseByUserId(user.id);
        if (fetchedResponses.data) {
          const filteredResponses = fetchedResponses.data.filter(
            (response: any) => response.userType === false
          );
          setResponses(filteredResponses);
        }
      } catch (error) {
        console.error("Failed to fetch questions and responses:", error);
      }
    };

    fetchResponses();
  }, [user.id]);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!category) {
      alert("Please select a category.");
      return;
    }
    if (!title.trim()) {
      alert("Please enter a title.");
      return;
    }
    if (!email.trim()) {
      alert("Please enter an email.");
      return;
    }
    if (!question.trim()) {
      alert("Please enter your question in the FAQ section.");
      return;
    }

    const categoryId = parseInt(category, 10);
    const payload: {
      userId: string;
      userName: string;
      userType: boolean;
      categoryId: number;
      email: string;
      title: string;
      questionContent: string;
      status: number;
      imageUrl?: string;
    } = {
      userId: user.id,
      userName: user.name,
      userType: userType,
      categoryId: categoryId,
      email: email,
      title: title,
      questionContent: question,
      status: 0,
    };

    try {
      setDisabled(true); // 버튼을 비활성화
      setLoading(true); // 로딩 상태 설정
      const response = await reqCreateQuestionAndResponse(payload);

      // 질문이 성공적으로 생성되었는지 확인
      if (response.data && response.data.id) {
        const questionId = response.data.id; // 생성된 질문 ID

        // 이미지 파일이 있는 경우에만 업로드 수행
        if (imageFile) {
          const uploadResponse = await reqUploadQNAImage(questionId, imageFile);

          if (uploadResponse.data) {
            payload.imageUrl = uploadResponse.data.imageUrl;
          } else {
            alert("Image upload failed. Please try again.");
            console.error("Image upload response error:", uploadResponse.data);
            setDisabled(false);
            return;
          }
        }

        // 질문 생성과 이미지 업로드 성공 후
        const badgePayload = {
          badgeName: ADMIN_BADGE.QNA,
        };
        await reqAddAdminBadge(badgePayload);
        console.log(response);
        alert("Your question has been successfully sent.");
        window.location.reload();
        setLoading(false);
      } else {
        throw new Error("Failed to create question.");
      }
    } catch (error) {
      console.error("Failed to send question:", error);
      alert("There was a problem sending your question.");
    }

    // 폼 필드 리셋
    setCategory("");
    setTitle("");
    setQuestion("");
    setEmail("");
    setImageFile(null);
  };

  const handleStatusClick = (response: Response) => {
    setSelectedResponse(response);
    setIsModalOpen(true);
    nav(`/customer/support/${response.id}`);
  };

  const closeModal = () => {
    setSelectedResponse(null);
    setIsModalOpen(false);
  };

  // Image input handler
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setImageFile(event.target.files[0]);
    }
  };

  // Prevent spaces in email field
  const handleKeyPress = (event: any) => {
    if (event.key === " ") {
      event.preventDefault();
    }
  };

  return (
    <Wrapper>
      <CustomerPageTitle title="Q&A" />

      {/* 요청 생성란 */}
      <MCard>
        <MPageTitle
          style={{
            borderBottom: "1px solid lightgray",
            margin: "0",
            paddingBottom: "1rem",
          }}
        >
          Create Question
        </MPageTitle>
        {/* 단일 요청 */}
        <MItem style={{ paddingBottom: "1rem" }}>
          <div>
            <div className="input-label">Category</div>
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-dark"
                id="dropdown-basic"
                style={{ width: "100%" }}
              >
                {dropdownText}
              </Dropdown.Toggle>

              <Dropdown.Menu style={{ width: "100%", textAlign: "center" }}>
                {Object.entries(CODE_QNA_STATUS).map(([key, value]) => (
                  <Dropdown.Item
                    key={key}
                    onClick={() => {
                      setCategory(key);
                      setDropdownText(value);
                    }}
                  >
                    {value}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>

          <div>
            <label htmlFor="titleInput input-label">Title</label>
            <input
              type="text"
              id="titleInput"
              className="form-control"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
            />
          </div>

          <div>
            <label htmlFor="emailInput input-label">Email</label>
            <input
              type="email"
              id="emailInput"
              className="form-control"
              value={email}
              onKeyPress={handleKeyPress}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Please enter your email."
            />
          </div>

          <div>
            <label htmlFor="questionInput input-label">Q&A</label>
            <textarea
              id="questionInput"
              className="form-control"
              rows={5}
              value={question}
              onChange={(e) => setQuestion(e.target.value)}
              placeholder="Type your questions here."
            ></textarea>
          </div>

          <div>
            <label htmlFor="imageInput input-label">Upload Image</label>
            <input
              type="file"
              id="imageInput"
              className="form-control"
              accept="image/*"
              onChange={handleImageChange}
            />
          </div>
        </MItem>

        {/* 제출 */}
        <div>
          <MButton
            style={{
              backgroundColor: disabled ? "gray" : "var(--color-main-blue)",
              color: "var(--color-white)",
              marginTop: "1rem",
            }}
            onClick={!disabled ? handleSubmit : undefined}
          >
            {loading ? "Sending..." : "Send"}
          </MButton>
        </div>
      </MCard>

      {isMobile ? (
        <div style={{ marginTop: "1rem", marginBottom: "4rem" }}>
          <MPageTitle>Question History</MPageTitle>
          {responses.map((item) => (
            <MItem key={item.id}>
              <MRow>
                <div className="label">Question type</div>
                <div className="data">{CODE_QNA_STATUS[item.categoryId]}</div>
              </MRow>

              <MRow>
                <div className="label">Registration date</div>
                <div className="data">{formatDate(item.createdAt)}</div>
              </MRow>

              <MRow>
                <div className="label">Reply date</div>
                <div className="data">
                  {item.responseAt
                    ? formatDate(item.responseAt)
                    : "Waiting for your answer"}
                </div>
              </MRow>

              <MRow>
                <div className="label">Title</div>
                <div className="data">{item.title}</div>
              </MRow>

              <MRow>
                <div className="label">Status</div>
                <div className="data">
                  {item.status ? "Completion" : "Not done"}
                </div>
              </MRow>

              <MButton
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                  margin: "0 0 .5rem 0",
                }}
                onClick={() => handleStatusClick(item)}
              >
                View
              </MButton>
            </MItem>
          ))}
        </div>
      ) : (
        <div className="container-fluid" style={{ marginTop: "1.4rem" }}>
          <MPageTitle>Question History</MPageTitle>
          <table className="table text-center">
            <thead>
              <tr>
                <th>Question type</th>
                <th>Registration date</th>
                <th>Reply date</th>
                <th>Title</th>
                <th>Status</th>
                <th>View</th>
              </tr>
            </thead>
            <tbody>
              {responses.map((item) => (
                <tr key={item.id} onClick={() => handleStatusClick(item)}>
                  <td>{CODE_QNA_STATUS[item.categoryId]}</td>
                  <td>{formatDate(item.createdAt)}</td>
                  <td>
                    {item.responseAt
                      ? formatDate(item.responseAt)
                      : "Waiting for your answer"}
                  </td>
                  <td>{item.title}</td>
                  <td>{item.status ? "Completion" : "Not done"}</td>
                  <td>
                    <MSmallButton
                      style={{
                        backgroundColor: "var(--color-main-blue)",
                        color: "var(--color-white)",
                        margin: "0 0 .5rem 0",
                      }}
                      onClick={() => handleStatusClick(item)}
                    >
                      View
                    </MSmallButton>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {selectedResponse && (
        <ModalWrapper isOpen={isModalOpen}>
          <ModalContent>
            <ModalHeader>
              <h1>{selectedResponse.title}</h1>
            </ModalHeader>
            <ModalBody>
              <div className="container-fluid">
                <div className="card">
                  <div className="card-header">
                    <table className="table">
                      <tbody>
                        <HideOnMobile>
                          <th>Space code</th>
                          <td>{selectedResponse.userId}</td>
                        </HideOnMobile>
                        <HideOnMobile>
                          <th>E-mail</th>
                          <td>{selectedResponse.email}</td>
                        </HideOnMobile>
                      </tbody>
                    </table>
                  </div>
                  <div className="card-body">
                    <h5 className="mb-2">Content</h5>
                    <p>{selectedResponse.questionContent}</p>
                  </div>
                  <div className="card-footer">
                    <div className="form-floating">
                      <h5 className="mb-2">Answer</h5>
                      <p>{selectedResponse.responseContent}</p>
                    </div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <StyledButton onClick={closeModal}>Close</StyledButton>
            </ModalFooter>
          </ModalContent>
        </ModalWrapper>
      )}
    </Wrapper>
  );
};

export default CSupportForm;

const Wrapper = styled.div`
  padding: 30px;

  @media (max-width: 768px) {
    padding: 1.4rem;
    margin-bottom: 4rem;
  }
`;

const ModalWrapper = styled.div<ModalWrapperProps>`
  display: ${(props) => (props.isOpen ? "block" : "none")};
  position: fixed;
  z-index: 2000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
`;

const ModalContent = styled.div`
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 90%;
  max-width: 500px;
  max-height: 90vh;
  overflow: auto;
  position: relative;

  @media (max-width: 767px) {
    width: 90%;
    max-height: 80vh;
    overflow: auto;
  }

  @media (min-width: 768px) {
    max-width: 1000px;
  }
`;

const ModalBody = styled.div`
  padding: 20px;

  @media (max-width: 767px) {
    padding: 5px;
  }
`;

const ModalHeader = styled.div`
  padding: 16px 24px;
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
  font-size: 1.25rem;
`;

const ModalFooter = styled.div`
  padding: 12px 24px;
  border-top: 1px solid #ddd;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const HideOnMobile = styled.tr`
  @media (max-width: 767px) {
    display: none;
  }
`;

const StyledButton = styled.button`
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  outline: none;
  transition: background-color 0.3s, box-shadow 0.3s, transform 0.2s;

  &:hover {
    background-color: #0056b3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
  }

  &:active {
    background-color: #004080;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }

  &:focus {
    outline: none; // Remove focus outline
    box-shadow: 0 0 0 3px rgba(38, 143, 255, 0.5);
  }
`;
