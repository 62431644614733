import { useEffect, useState } from "react";
import {
  Card,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Table,
  Tabs,
} from "react-bootstrap";
import { Check, CheckCircle } from "react-bootstrap-icons";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import {
  CODE_COUNTRY_ITEMS,
  CODE_SHIPPING_COMPANY,
  CODE_SHIPPING_ORDER_STATUS,
} from "../../common/constants";
import {
  MBOptionRow,
  MBody,
  MButton,
  MFooter,
  MHeader,
  MRow,
  MTabBody,
  MTabHeader,
  MTabSection,
  MTableBody,
  MWrapper,
} from "../../common/mobile-styled";
import {
  reqShip,
  reqShipDetail,
  reqShipFEE,
  reqShipOrderUpdate,
  reqShipProductDetail,
  reqShipTrackingGet,
  reqShipUpdateOrder,
} from "../../requests/ship";
import Icon from "../Icon";
import { reqGetMembership } from "../../requests/warehouse";
interface CShipPayModal {
  show: boolean;
  onHide: () => void;
  orderNumber: string;
  onRepackRequest: () => void;
  refreshData: () => void;
}
interface Product {
  tracking: string;
  warehouseId: string;
  isUrgent: boolean;
  bag: boolean;
  name: string;
  warehouse: string;
  overstoreFee: number;
  Extra: number;
  price: number;
  boxed: number;
}

interface PackingDetail {
  id: string;
  totalWeightKg: number;
  totalHeightCm: number;
  totalWidthCm: number;
  totalLengthCm: number;
  trackingNumbers: string;
  suspectBattery: boolean;
  food: boolean;
  express: string;
  imageUrl: string;
  isDeleted: boolean;
}

interface OrderDetails {
  user: User;
  products: Product[];
  id: string;
  company: number;
  optionBox: number;
  optionLetter: boolean;
  optionPoster: boolean;
  optionAircap: boolean;
  optionBag: boolean;
  optionSticker: boolean;
  adrReceiver: string;
  adrEmail: string;
  adrPhone: string;
  adrCountry: number;
  adrPCCC: string;
  adrZip: string;
  adrState: string;
  adrCity: string;
  adrOption1: string;
  adrOption2: string;
  adrOption3: string;
  estimatedAt: string;
  expiredAt: string;
  weightKg: number;
  heightCm: number;
  widthCm: number;
  lengthCm: number;
  suspectBattery: boolean;
  food: boolean;
  packingStatus: number;
  customerMessage: string;
  managerMessage: string;
  depositor: string;
  DepositorFee: string;
  link: string;
  useVacuumPackagingBag: number;
  FEDEXPriceKRW: number | null;
  FEDEXPriceUSD: number | null;
  UPSPriceKRW: number | null;
  UPSPriceUSD: number | null;
  EMSPriceKRW: number | null;
  EMSPriceUSD: number | null;
  PACKETPriceKRW: number | null;
  PACKETPriceUSD: number | null;
  CJPriceKRW: number | null;
  CJPriceUSD: number | null;
  packagPriceKRW: number | null;
  packagPriceUSD: number | null;
  PaymentMethod: number;
  usePointKrw: number;
  usePointUse: number;
  discountCouponKrw: number;
  discountCouponUsd: number;
}
interface TrackingInfo {
  id: number;
  orderId: string;
  orderIds: string;
  trackingNumbers: string;
}
interface Product {
  boxed: number;
}

interface ShippingRate {
  KRW: number | null;
  USD: number | null;
}

interface ShippingOptions {
  [carrier: string]: ShippingRate;
}

interface User {
  id: string;
  email: string;
  membershipId: number;
}

interface ShippingOptionRates {
  KRW: number | null;
  USD: number | null;
}

interface CheckedShippingOptions {
  [carrier: string]: boolean;
}

export default function CShipDetailModal({
  show,
  onHide,
  orderNumber,
  onRepackRequest,
  refreshData,
}: CShipPayModal) {
  const defaultUser: User = {
    id: "",
    email: "",
    membershipId: 0,
  };
  const [exchangeRate, setExchangeRate] = useState(0);
  const [key, setKey] = useState("Request Information");
  const [excludedProducts, setExcludedProducts] = useState<Product[]>([]);
  const [paymentDeadline, setPaymentDeadline] = useState("");
  const [customerMessage, setCustomerMessage] = useState("");
  const [fedexKrw, setFedexKrw] = useState("");
  const [fedexUsd, setFedexUsd] = useState("");
  const [shippingOptions, setShippingOptions] = useState<ShippingOptions>({});
  const [checkedShippingOptions, setCheckedShippingOptions] =
    useState<CheckedShippingOptions>({});
  const [depositorName, setDepositorName] = useState("");
  const [depositAmount, setDepositAmount] = useState("");
  const [link, setLink] = useState("");
  const [showKRWPModal, setShowKRWPModal] = useState(false);
  const [totalSurchargesUSD, setTotalSurchargesUSD] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState<{ [key: string]: boolean }>({});
  const [packingDetails, setPackingDetails] = useState<PackingDetail[]>([]);
  const [trackingInfo, setTrackingInfo] = useState<TrackingInfo[]>([]);
  const [depositor, setDepositor] = useState("");
  const [depositorFee, setDepositorFee] = useState("");
  const [paymentLink, setPaymentLink] = useState("");
  const activePackingDetails = packingDetails.filter(
    (detail) => !detail.isDeleted
  );
  const [orderDetails, setOrderDetails] = useState<OrderDetails>({
    products: [],
    user: defaultUser,
    id: "",
    optionBox: 0,
    company: 0,
    optionLetter: false,
    optionPoster: false,
    optionAircap: false,
    optionBag: false,
    optionSticker: false,
    adrReceiver: "",
    adrEmail: "",
    adrPhone: "",
    adrCountry: 0,
    useVacuumPackagingBag: 0,
    adrPCCC: "",
    adrZip: "",
    adrState: "",
    adrCity: "",
    adrOption1: "",
    adrOption2: "",
    adrOption3: "",
    estimatedAt: "",
    expiredAt: "",
    weightKg: 0,
    heightCm: 0,
    widthCm: 0,
    lengthCm: 0,
    depositor: "",
    DepositorFee: "",
    link: "",
    suspectBattery: false,
    food: false,
    packingStatus: 0,
    customerMessage: "",
    managerMessage: "",
    FEDEXPriceKRW: 0,
    FEDEXPriceUSD: 0,
    UPSPriceKRW: 0,
    UPSPriceUSD: 0,
    EMSPriceKRW: 0,
    EMSPriceUSD: 0,
    PACKETPriceKRW: 0,
    PACKETPriceUSD: 0,
    CJPriceKRW: 0,
    CJPriceUSD: 0,
    packagPriceKRW: 0,
    packagPriceUSD: 0,
    PaymentMethod: 0,
    usePointKrw: 0,
    usePointUse: 0,
    discountCouponKrw: 0,
    discountCouponUsd: 0,
  });

  const handleSelect = (k: any) => {
    setKey(k);
  };

  useEffect(() => {
    if (orderNumber) {
      fetchOrderDetails();
    }
  }, [orderNumber]);

  const fetchOrderDetails = async () => {
    try {
      const response = await reqShip({ id: orderNumber });
      const orderData: OrderDetails = response.data;

      const availableShippingOptions: ShippingOptions = {
        FEDEX: { KRW: orderData.FEDEXPriceKRW, USD: orderData.FEDEXPriceUSD },
        UPS: {
          KRW: orderData.UPSPriceKRW ?? null,
          USD: orderData.UPSPriceUSD ?? null,
        },
        EMS: {
          KRW: orderData.EMSPriceKRW ?? null,
          USD: orderData.EMSPriceUSD ?? null,
        },
        PACKET: {
          KRW: orderData.PACKETPriceKRW ?? null,
          USD: orderData.PACKETPriceUSD ?? null,
        },
        CJ: {
          KRW: orderData.CJPriceKRW ?? null,
          USD: orderData.CJPriceUSD ?? null,
        },
      };

      const filteredOptions = Object.entries(availableShippingOptions)
        .filter(([_, rates]) => rates.KRW !== null && rates.USD !== null)
        .reduce((acc, [carrier, rates]) => ({ ...acc, [carrier]: rates }), {});

      setShippingOptions(filteredOptions);
      setOrderDetails(orderData);
      const excluded = orderData.products.filter((p: Product) => !p.boxed);
      setExcludedProducts(excluded);
    } catch (error) {
      console.error("Error fetching order details:", error);
    }
  };

  const [exchangeRateN, setExchangeRateN] = useState<number>(0);

  // const fetchExchangeRateN = () => {
  //   fetch(
  //     "https://quotation-api-cdn.dunamu.com/v1/forex/recent?codes=FRX.KRWUSD"
  //   )
  //     .then((response) => response.json())
  //     .then((data) => {
  //       const rate = data[0].basePrice;
  //       setExchangeRateN(rate);
  //     })
  //     .catch((error) => console.error("Error fetching exchange rate:", error));
  // };

  const fetchFixedExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      if (
        response &&
        response.data &&
        response.data[0]?.fixedExchangeRateShop
      ) {
        setFixedExchangeRate(
          parseFloat(response.data[0].fixedExchangeRateShop)
        );
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };
  const [fixedExchangeRate, setFixedExchangeRate] = useState(0);

  useEffect(() => {
    // fetchExchangeRateN();
    fetchFixedExchangeRate();
  });

  const convertToKRW = (usd: number) => {
    return Math.floor(usd * 1000).toLocaleString();
  };

  const convertToUSD = (krw: number) => {
    return (krw / exchangeRate).toFixed(2);
  };

  useEffect(() => {
    fetchExchangeRate();
  }, []);

  const fetchExchangeRate = async () => {
    try {
      const response = await reqShipFEE();
      const fixedExchangeRate = response.data[0]?.fixedExchangeRate;
      if (fixedExchangeRate) {
        setExchangeRate(parseFloat(fixedExchangeRate));
      } else {
        console.error("Failed to fetch fixed exchange rate: No data");
      }
    } catch (error) {
      console.error("Error fetching fixed exchange rate:", error);
    }
  };

  useEffect(() => {
    const fetchTrackingInfo = async () => {
      try {
        const response = await reqShipTrackingGet({ id: orderNumber });
        setTrackingInfo(response.data);
      } catch (error) {
        console.error("Error fetching tracking information: ", error);
      }
    };

    if (orderNumber) {
      fetchTrackingInfo();
    }
  }, [orderNumber]);

  const handleUpdate = async () => {
    const now = new Date();
    const estimatedAt = now.toISOString();

    const expiredAt = paymentDeadline;

    await reqShipUpdateOrder({
      id: orderNumber,
      status: CODE_SHIPPING_ORDER_STATUS.PAID,
    });

    await reqShipProductDetail({
      id: orderNumber,
    });

    await reqShipOrderUpdate({
      id: orderNumber,
      expiredAt: expiredAt,
    });

    onRepackRequest();
    onHide();
    refreshData();
  };

  const [isZoomed, setIsZoomed] = useState(false);

  // const handleImageClick = () => {
  //   setIsZoomed(!isZoomed);
  // };

  const [imageToShow, setImageToShow] = useState("");
  const [showImageModal, setShowImageModal] = useState(false);

  const handleImageClick = (imageUrl: string) => {
    setImageToShow(imageUrl);
    setShowImageModal(true);
  };

  const closeImageModal = () => {
    setShowImageModal(false);
  };

  const handleShippingOptionChange = (carrier: string) => {
    setCheckedShippingOptions((prev) => ({
      ...prev,
      [carrier]: !prev[carrier],
    }));
  };

  const calculateTotalKRWForCheckedOptions = (): number => {
    return Object.entries(shippingOptions)
      .filter(([carrier]) => checkedShippingOptions[carrier])
      .reduce((total, [_, rates]) => total + (rates.KRW ?? 0), 0);
  };

  const handleRepackageClick = () => {
    const totalKRW = calculateTotalKRWForCheckedOptions();
    setDepositAmount(totalKRW.toString());
    setShowKRWPModal(true);
  };

  const handleKRWPaymentSubmit = async () => {
    try {
      const depositorNameToSend = depositorName;
      const depositAmountToSend = depositAmount.toString();
      const linkToSend = link;

      await reqShipOrderUpdate({
        id: orderNumber,
        depositor: depositorNameToSend,
        DepositorFee: depositAmountToSend,
        link: linkToSend,
      });

      console.log("KRW Payment submitted successfully");

      onRepackRequest();
      onHide();
      refreshData();
    } catch (error) {
      console.error("Failed to process KRW payment: ", error);
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await reqShip({ id: orderNumber });
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details: ", error);
      }
    };

    if (orderNumber) {
      fetchOrderDetails();
    }
    const fetchPackingDetails = async () => {
      try {
        const response = await reqShipDetail({ order_id: orderNumber });
        setPackingDetails(response.data);
      } catch (error) {
        console.error("Error fetching packing details: ", error);
      }
    };

    if (orderNumber) {
      fetchPackingDetails();
    }
  }, [orderNumber]);

  const getPackingStatusText = (status: number) => {
    switch (status) {
      case 1:
        return "Complete packaging";
      case 2:
        return "lack of space";
      case 3:
        return "Over 30kg";
      default:
        return "No Information";
    }
  };

  const calculateOptionCosts = () => {
    const costs = {
      stickerPerBox: 2000,
      aircapPerBox: 3000,
      insurancePerBox: 5000,
      vacuumPackPerTracking: 5000,
    };

    const actualBoxCount = packingDetails.filter(
      (detail) => !detail.isDeleted
    ).length;

    let totalCostKRW = 0;
    let totalCostUSD = 0;

    if (orderDetails.optionSticker) {
      totalCostKRW += costs.stickerPerBox * actualBoxCount;
      totalCostUSD += (costs.stickerPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionAircap) {
      totalCostKRW += costs.aircapPerBox * actualBoxCount;
      totalCostUSD += (costs.aircapPerBox * actualBoxCount) / exchangeRate;
    }
    if (orderDetails.optionBag) {
      totalCostKRW += costs.insurancePerBox * actualBoxCount;
      totalCostUSD += (costs.insurancePerBox * actualBoxCount) / exchangeRate;
    }

    const productsUsingVacuumPack = orderDetails.products.filter(
      (product) => product.bag === true && product.boxed !== 0
    );
    totalCostKRW +=
      costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag;
    totalCostUSD +=
      (costs.vacuumPackPerTracking * orderDetails.useVacuumPackagingBag) /
      exchangeRate;

    return { totalCostKRW, totalCostUSD };
  };

  const { totalCostKRW, totalCostUSD } = calculateOptionCosts();

  // 무게측정

  const [membershipData, setMembershipData] = useState<any[]>([]);
  const [repurchaseDiscounts, setRepurchaseDiscounts] = useState({
    blue: 0,
    green: 0,
    red: 0,
  });

  // API 호출 함수
  const fetchMembershipData = async () => {
    try {
      const response = await reqGetMembership(); // API 호출
      const apiData = response.data;

      setMembershipData(apiData);

      // id === 1인 항목에서 repurchaseDiscountBlue, Green, Red 값을 추출
      const targetMembership = apiData.find((item: any) => item.id === 1);
      if (targetMembership) {
        setRepurchaseDiscounts({
          blue: targetMembership.repurchaseDiscountBule,
          green: targetMembership.repurchaseDiscountGreen,
          red: targetMembership.repurchaseDiscountRed,
        });
      }
    } catch (error) {
      console.error("Error fetching membership data:", error);
    }
  };

  useEffect(() => {
    fetchMembershipData();
  }, []);

  const totalWeightSum = packingDetails.reduce((sum, detail) => {
    const weight = Number(detail.totalWeightKg);
    return sum + (isNaN(weight) ? 0 : weight);
  }, 0);

  const membershipDiscounts = {
    1: repurchaseDiscounts.red / 100,
    2: repurchaseDiscounts.blue / 100,
    3: repurchaseDiscounts.green / 100,
  };

  const calculateMembershipDiscountRate = () => {
    const membershipDiscountRates = {
      1: repurchaseDiscounts.red,
      2: repurchaseDiscounts.blue,
      3: repurchaseDiscounts.green,
    };

    const discountRate =
      membershipDiscountRates[
        orderDetails.user.membershipId as keyof typeof membershipDiscountRates
      ] || 0;
    return discountRate;
  };

  const calculateRepackFee = () => {
    const boxCounts = new Map();
    orderDetails.products.forEach((product) => {
      boxCounts.set(product.boxed, (boxCounts.get(product.boxed) || 0) + 1);
    });

    let totalRepackFeeKRW = 0;
    let totalRepackFeeUSD = 0;

    boxCounts.forEach((count, boxNumber) => {
      if (count > 1) {
        const packingDetail = packingDetails[boxNumber - 1];
        if (packingDetail) {
          const repackFeePerBox = packingDetail.totalWeightKg * 1000;
          totalRepackFeeKRW += repackFeePerBox;
          totalRepackFeeUSD += repackFeePerBox / 1000;
        }
      }
    });

    let discountRate =
      membershipDiscounts[
        orderDetails.user.membershipId as keyof typeof membershipDiscounts
      ] || 0;
    totalRepackFeeKRW *= 1 - discountRate;
    totalRepackFeeUSD *= 1 - discountRate;

    return {
      repackFeeKRW: totalRepackFeeKRW.toLocaleString("ko-KR"),
      repackFeeUSD: totalRepackFeeUSD.toFixed(2),
    };
  };

  const [warehouseTotalKRW, warehouseTotalUSD] = [
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Math.round(Number(product.overstoreFee) * 1000)) +
          product.Extra,
        0
      )
      .toLocaleString("ko-KR"),
    orderDetails.products
      .filter((product) => product.boxed !== 0)
      .reduce(
        (acc, product) =>
          acc +
          (isNaN(Number(product.overstoreFee))
            ? 0
            : Number(product.overstoreFee)) +
          product.Extra / exchangeRate,
        0
      )
      .toFixed(2),
  ];

  const getShippingOptionLabel = (companyCode: number) => {
    switch (companyCode) {
      case 1:
        return "FEDEX";
      case 2:
        return "UPS";
      case 3:
        return "EMS";
      case 4:
        return "PACKET";
      case 5:
        return "CJ";
      default:
        return "Unknown";
    }
  };

  const renderShippingOption = (carrier: any, rates: any) => {
    if (carrier === getShippingOptionLabel(orderDetails.company)) {
      return (
        <div key={carrier}>
          <MRow>
            <div className="label ">{`${carrier} Rating Fee`}</div>
            <div className="data price">
              ${rates.USD ? rates.USD.toFixed(2) : "-"} / ₩
              {rates.KRW?.toLocaleString() || "-"}
            </div>
          </MRow>
        </div>
      );
    }
    return null;
  };

  const { repackFeeKRW, repackFeeUSD } = calculateRepackFee();

  const [openSections, setOpenSections] = useState<any>({
    "Request Information": false,
    "Product Not Included": false,
    Address: false,
    "Packaging Information": false,
    Estimate: false,
    "KRW Payment": false,
    "USD Payment": false,
    Shipping: false,
  });
  const toggleSection = (section: string) => {
    setOpenSections((prev: any) => ({ ...prev, [section]: !prev[section] }));
  };

  // Request Information 섹션 대상
  const [riOpenIndexes, setRiOpenIndexes] = useState<number[]>([]);
  const riToggleOpen = (index: number) => {
    setRiOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  // Product Not Included 섹션 대상
  const [pniOpenIndexes, setPniOpenIndexes] = useState<number[]>([]);
  const pniToggleOpen = (index: number) => {
    setPniOpenIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
    );
  };

  const calculateVolumeWeight5000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 5000;
  };

  const calculateVolumeWeight6000 = (
    width: number,
    length: number,
    height: number
  ) => {
    return (width * length * height) / 6000;
  };

  const roundToFirstDecimal = (num: any) => {
    const integerPart = Math.floor(num);
    const decimalPart = num - integerPart;

    if (decimalPart <= 0.5) {
      return integerPart + 0.5;
    } else {
      return integerPart + 1;
    }
  };

  // ! 모바일
  const sectionContents: any = {
    "Request Information": (
      <MTabBody>
        <div className="sub-header">- Service Options</div>
        <div className="line">
          <MBOptionRow
            label="Pack only one box"
            disabled
            checked={orderDetails.optionBox === 0}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionBox: e.target.checked ? 1 : 0,
              })
            }
          />
          <MBOptionRow
            label="Letter Bag Box Packaging"
            disabled
            checked={orderDetails.optionLetter}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionLetter: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Minimize Poster Tubes"
            disabled
            checked={orderDetails.optionPoster}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionPoster: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Add Air Cap"
            disabled
            checked={orderDetails.optionAircap}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionAircap: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Stickers on 4 Sides"
            disabled
            checked={orderDetails.optionSticker}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionSticker: e.target.checked,
              })
            }
          />
          <MBOptionRow
            label="Insurance coverage"
            disabled
            checked={orderDetails.optionBag}
            onChange={(e) =>
              setOrderDetails({
                ...orderDetails,
                optionBag: e.target.checked,
              })
            }
          />
        </div>

        <div className="sub-header">- Shipping Information</div>
        <div>
          {orderDetails.products.map((product, index) => (
            <div className="dropdown-box" key={index}>
              <div className="dropdown-bar" onClick={() => riToggleOpen(index)}>
                <div className="dropdown-icon">
                  {riOpenIndexes.includes(index) ? (
                    <Icon icon="caret-down-fill" />
                  ) : (
                    <Icon icon="caret-up" />
                  )}
                </div>

                <div
                  style={{
                    textDecoration: product.boxed ? "none" : "line-through",
                  }}
                >
                  {product.tracking}
                </div>
              </div>

              <div className="dropdown-data">
                {riOpenIndexes.includes(index) && (
                  <>
                    <MRow>
                      <div className="label">NO</div>
                      <div className="data">{index + 1}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Tracking Number</div>
                      <div
                        className="data"
                        style={{
                          textDecoration: product.boxed
                            ? "none"
                            : "line-through",
                        }}
                      >
                        {product.tracking}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Warehouse Costs</div>
                      <div className="data price">
                        ${convertToUSD(product.overstoreFee)} / ₩
                        {convertToKRW(product.overstoreFee)}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Extra</div>
                      <div className="data price">
                        ${convertToUSD(product.Extra)} / ₩{product.Extra}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Required Inclusion</div>
                      <div className="data check-box">
                        <input
                          type="checkbox"
                          checked={product.isUrgent}
                          disabled
                        />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Vacuum Packing Bag</div>
                      <div className="data check-box">
                        <input type="checkbox" checked={product.bag} disabled />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">BOX</div>
                      <div className="data">{product.boxed}</div>
                    </MRow>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </MTabBody>
    ),
    "Product Not Included": (
      <MTabBody>
        <div>
          <div className="sub-header">- Product Options</div>
          <div className="line">
            <MBOptionRow
              type="checkbox"
              id="batteryCheck"
              label="Suspected Products Including Batteries"
              checked={packingDetails.some((detail) => detail.suspectBattery)}
              disabled
            />
            <MBOptionRow
              type="checkbox"
              id="foodCheck"
              label="Food"
              checked={packingDetails.some((detail) => detail.food)}
              disabled
            />
          </div>
        </div>

        <div>
          <div className="sub-header">- Packaging Status</div>

          <MButton
            style={{
              backgroundColor: "var(--color-main-blue)",
              color: "var(--color-white)",
              marginBottom: ".6rem",
            }}
          >
            {getPackingStatusText(orderDetails.packingStatus)}
          </MButton>

          {excludedProducts.map((product, index) => (
            <div className="dropdown-box" key={index}>
              <div
                className="dropdown-bar"
                onClick={() => pniToggleOpen(index)}
              >
                <div className="dropdown-icon">
                  {pniOpenIndexes.includes(index) ? (
                    <Icon icon="caret-down-fill" />
                  ) : (
                    <Icon icon="caret-up" />
                  )}
                </div>

                <div
                  style={{
                    textDecoration: product.boxed ? "none" : "line-through",
                  }}
                >
                  {product.tracking}
                </div>
              </div>

              <div className="dropdown-data">
                {pniOpenIndexes.includes(index) && (
                  <>
                    <MRow>
                      <div className="label">NO</div>
                      <div className="data">{index + 1}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Tracking Number</div>
                      <div className="data">{product.tracking}</div>
                    </MRow>

                    <MRow>
                      <div className="label">Warehouse Costs</div>
                      <div className="data">
                        ${product.overstoreFee} / ₩
                        {convertToKRW(product.overstoreFee)}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Extra</div>
                      <div className="data">
                        ${convertToUSD(product.Extra)} / ₩{product.Extra}
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Required Inclusion</div>
                      <div className="data">
                        <Form.Check
                          type="checkbox"
                          checked={product.isUrgent}
                          disabled
                        />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">Vacuum Packing Bag</div>
                      <div className="data">
                        <Form.Check
                          type="checkbox"
                          checked={product.bag}
                          disabled
                        />
                      </div>
                    </MRow>

                    <MRow>
                      <div className="label">BOX</div>
                      <div className="data">{"-"}</div>
                    </MRow>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      </MTabBody>
    ),
    Address: (
      <MTabBody>
        <div className="sub-header">- Shipping Address</div>
        <div>
          <MRow>
            <div className="label">Recipient</div>
            <div className="data">{orderDetails.adrReceiver}</div>
          </MRow>

          <MRow>
            <div className="label">E-mail</div>
            <div className="data">{orderDetails.adrEmail}</div>
          </MRow>

          <MRow>
            <div className="label">Phone Number</div>
            <div className="data">{orderDetails.adrPhone}</div>
          </MRow>

          <MRow>
            <div className="label">Country</div>
            <div className="data">
              {CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                "Country not selected"}
            </div>
          </MRow>

          <MRow>
            <div className="label">PCC</div>
            <div className="data">{orderDetails.adrPCCC}</div>
          </MRow>

          <MRow>
            <div className="label">Zip Code</div>
            <div className="data">{orderDetails.adrZip}</div>
          </MRow>

          <MRow>
            <div className="label">State</div>
            <div className="data">{orderDetails.adrState}</div>
          </MRow>

          <MRow>
            <div className="label">City</div>
            <div className="data">{orderDetails.adrCity}</div>
          </MRow>

          <MRow>
            <div className="label">Address-1(35)</div>
            <div className="data">{orderDetails.adrOption1}</div>
          </MRow>

          <MRow>
            <div className="label">Address-2(35)</div>
            <div className="data">{orderDetails.adrOption2}</div>
          </MRow>

          <MRow>
            <div className="label">Address-3(35)</div>
            <div className="data">{orderDetails.adrOption3}</div>
          </MRow>
        </div>
      </MTabBody>
    ),
    "Packaging Information": (
      <MTabBody>
        <div className="sub-header">- Packaging Information</div>
        {packingDetails
          .filter((detail) => !detail.isDeleted)
          .map((detail, index) => {
            const volumeWeight5000 = parseFloat(
              calculateVolumeWeight5000(
                detail.totalWidthCm,
                detail.totalLengthCm,
                detail.totalHeightCm
              ).toFixed(2)
            );
            const volumeWeight6000 = parseFloat(
              calculateVolumeWeight6000(
                detail.totalWidthCm,
                detail.totalLengthCm,
                detail.totalHeightCm
              ).toFixed(2)
            );

            const roundedWeight5000 = roundToFirstDecimal(volumeWeight5000);
            const roundedWeight6000 = roundToFirstDecimal(volumeWeight6000);

            const displayWeight5000 = Math.max(
              detail.totalWeightKg,
              roundedWeight5000
            );
            const displayWeight6000 = Math.max(
              detail.totalWeightKg,
              roundedWeight6000
            );

            return (
              <div key={index}>
                <div>
                  <div>Package Picture</div>
                  <div>
                    {detail.imageUrl ? (
                      <img
                        src={detail.imageUrl}
                        alt="Attached File"
                        style={{
                          marginTop: "0.4rem",
                          marginBottom: "0.4rem",
                          maxWidth: "-webkit-fill-available",
                          cursor: "pointer",
                        }}
                        // onClick={() => handleImageClick(detail.imageUrl)}
                      />
                    ) : (
                      "No image available"
                    )}
                  </div>
                </div>

                <MRow>
                  <div className="label">Product ID</div>
                  <div className="data">{detail.id}</div>
                </MRow>

                <MRow>
                  <div className="label">Weight(KG)</div>
                  <div className="data">{detail.totalWeightKg}</div>
                </MRow>

                <MRow>
                  <div className="label">Width(CM)</div>
                  <div className="data">{detail.totalWidthCm}</div>
                </MRow>

                <MRow>
                  <div className="label">Length(CM)</div>
                  <div className="data">{detail.totalLengthCm}</div>
                </MRow>

                <MRow>
                  <div className="label">Height(CM)</div>
                  <div className="data">{detail.totalHeightCm}</div>
                </MRow>

                <hr style={{ width: "100%", margin: "1rem 0" }} />

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    width: "100%",
                  }}
                >
                  <div style={{ fontWeight: "bold" }}>Applied Weight</div>
                </div>

                <MRow>
                  <div className="label">FedEx, UPS</div>
                  <div className="data">{displayWeight5000}</div>
                </MRow>

                <MRow>
                  <div className="label">EMS, K-Packet</div>
                  <div className="data">{displayWeight6000}</div>
                </MRow>
              </div>
            );
          })}
      </MTabBody>
    ),
    Estimate: (
      <MTableBody>
        <div className="line">
          <div className="sub-header">- Estimate</div>
          <div>
            <MRow>
              <div className="label">Delivery Country</div>
              <div className="data">
                {CODE_COUNTRY_ITEMS[Number(orderDetails.adrCountry)] ||
                  "Country not selected"}
              </div>
            </MRow>
            <MRow>
              <div className="label">Request Quantity</div>
              <div className="data">{orderDetails.products.length}</div>
            </MRow>
            <MRow>
              <div className="label">Quantity not Included</div>
              <div className="data">
                {
                  orderDetails.products.filter((product) => !product.boxed)
                    .length
                }
              </div>
            </MRow>
            <MRow>
              <div className="label">Reasons not Included</div>
              <div className="data">
                {getPackingStatusText(orderDetails.packingStatus)}
              </div>
            </MRow>
            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>
          </div>
        </div>

        {/* 포장비용 */}
        <div className="line">
          <div className="sub-header">- Packaging Cost</div>
          <div>
            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Weight</div>
              <div className="data">{Number(totalWeightSum).toFixed(2)} KG</div>
            </MRow>

            <MRow>
              <div className="label">Member's Discount Rate (%)</div>
              <div className="data">{calculateMembershipDiscountRate()}%</div>
            </MRow>

            <MRow>
              <div className="label">Cost</div>
              <div className="data price">
                ${repackFeeUSD} / ₩{repackFeeKRW}
              </div>
            </MRow>
          </div>
        </div>

        {/* 옵션비용 */}
        <div className="line">
          <div className="sub-header">- Option Fee</div>
          <div>
            <MRow>
              <div className="label">Box Quantity</div>
              <div className="data">
                {packingDetails.filter((detail) => !detail.isDeleted).length}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Weight</div>
              <div className="data">{Number(totalWeightSum).toFixed(2)} KG</div>
            </MRow>

            <MRow>
              <div className="label">Member's Discount Rate (%)</div>
              <div className="data">{calculateMembershipDiscountRate()}%</div>
            </MRow>

            <MRow>
              <div className="label">Cost</div>
              <div className="data price">
                ${repackFeeUSD} / ₩{repackFeeKRW}
              </div>
            </MRow>
          </div>
        </div>

        {/* 웨어하우스 비용 */}
        <div className="line">
          <div className="sub-header">- Warehouse Cost</div>
          <div>
            <MRow>
              <div className="label">Warehouse Cost</div>
              <div className="data price">
                $
                {orderDetails.products
                  .filter((product) => product.boxed !== 0)
                  .reduce((acc, product) => acc + product.overstoreFee, 0)
                  .toFixed(2)}{" "}
                / ₩
                {orderDetails.products
                  .filter((product) => product.boxed !== 0)
                  .reduce(
                    (acc, product) =>
                      acc + Math.round(product.overstoreFee * 1000),
                    0
                  )
                  .toLocaleString("ko-KR")}{" "}
              </div>
            </MRow>

            <MRow>
              <div className="label">Extra Charge</div>
              <div className="data price">
                $
                {orderDetails.products
                  .filter((product) => product.boxed !== 0)
                  .reduce(
                    (acc, product) => acc + product.Extra / exchangeRate,
                    0
                  )
                  .toFixed(2)}{" "}
                / ₩
                {orderDetails.products
                  .filter((product) => product.boxed !== 0)
                  .reduce((acc, product) => acc + product.Extra, 0)
                  .toLocaleString("ko-KR")}
              </div>
            </MRow>

            <MRow>
              <div className="label">Total Cost</div>
              <div className="data price">
                ${warehouseTotalUSD} / ₩{warehouseTotalKRW}
              </div>
            </MRow>
          </div>
        </div>

        {/* 배송비 */}
        <div className="line">
          <div className="sub-header">- Shipping Fee</div>
          {Object.entries(shippingOptions).map(([carrier, rates]) =>
            renderShippingOption(carrier, rates)
          )}
        </div>

        {/* 고객 메시지 입력 폼 */}
        <div>
          <div className="sub-header">- Charge Message</div>
          <div>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder="Thank you!"
              defaultValue={orderDetails.customerMessage}
              readOnly
            />
          </div>
        </div>
      </MTableBody>
    ),
    "KRW Payment": (
      <>
        {orderDetails.PaymentMethod === 1 ? (
          <MTabBody>
            <div className="line">
              <div className="sub-header">- Deposit Information</div>
              <div>
                <MRow>
                  <div className="label">Name of Depositor</div>
                  <div className="data">{orderDetails.depositor}</div>
                </MRow>

                <MRow>
                  <div className="label">Deposit Amount</div>
                  <div className="data">₩ {orderDetails.DepositorFee}</div>
                </MRow>

                <MRow>
                  <div className="label">Points to Use</div>
                  <div className="data">₩ {orderDetails.usePointKrw}</div>
                </MRow>

                <MRow>
                  <div className="label">Coupon discount amount</div>
                  <div className="data">₩ {orderDetails.discountCouponKrw}</div>
                </MRow>

                <MRow>
                  <div className="label">Link</div>
                  <div className="data">{orderDetails.link}</div>
                </MRow>
              </div>
            </div>

            <div>
              <div className="sub-header">- Bank Information</div>
              <div>
                <MRow>
                  <div className="label">Delivery Company</div>
                  <div className="data">
                    {CODE_SHIPPING_COMPANY[orderDetails?.company] ||
                      "Unallocated yet"}
                  </div>
                </MRow>
                <MRow>
                  <div className="label">Bank</div>
                  <div className="data">Woori Bank</div>
                </MRow>
                <MRow>
                  <div className="label">Account Holder</div>
                  <div className="data">ONTACT KOREA</div>
                </MRow>
                <MRow>
                  <div className="label">Bank Account Number</div>
                  <div className="data">1005-804-179216</div>
                </MRow>
                <MRow>
                  <div className="label">Deposit Amount</div>
                  <div className="data">{orderDetails.DepositorFee}</div>
                </MRow>
              </div>
            </div>
          </MTabBody>
        ) : null}
      </>
    ),
    "USD Payment": (
      <>
        {orderDetails.PaymentMethod === 2 ||
        orderDetails.PaymentMethod === 3 ? (
          <MTabBody>
            <div>
              <div className="sub-header">- Deposit Information</div>
              <div>
                {/* <MRow>
                  <div className="label">Name of Depositor</div>
                  <div className="data">{orderDetails.depositor}</div>
                </MRow> */}

                <MRow>
                  <div className="label">Deposit Amount</div>
                  <div className="data">$ {orderDetails.DepositorFee}</div>
                </MRow>
                <MRow>
                  <div className="label">Points to Use</div>
                  <div className="data">$ {orderDetails.usePointUse}</div>
                </MRow>
                <MRow>
                  <div className="label">Coupon discount amount</div>
                  <div className="data">$ {orderDetails.discountCouponUsd}</div>
                </MRow>
                {/* <MRow>
                  <div className="label">Link</div>
                  <div className="data">{orderDetails.link}</div>
                </MRow> */}

                <MRow>
                  <div className="label">Payment Method</div>
                  <div className="data">
                    {orderDetails.PaymentMethod === 2
                      ? "PayPal"
                      : orderDetails.PaymentMethod === 3
                      ? "Other"
                      : ""}
                  </div>
                </MRow>
              </div>
            </div>
          </MTabBody>
        ) : null}
      </>
    ),
    Shipping: (
      <MTabBody>
        <div>
          <div className="sub-header">- Packaging Status</div>
          {activePackingDetails.map((detail, index) => (
            <div key={index}>
              <MRow>
                <div className="label">RP-NUMBER</div>
                <div className="data">{detail.id}</div>
              </MRow>

              <MRow>
                <div className="label">TRACKING NUMBER</div>
                <div className="data">{detail.express || "Not entered"}</div>
              </MRow>

              <MButton
                style={{
                  backgroundColor: "var(--color-main-blue)",
                  color: "var(--color-white)",
                  margin: "0.6rem 0",
                }}
              >
                {detail.express ? (
                  <div
                    onClick={() => {
                      const url =
                        CODE_SHIPPING_COMPANY[orderDetails?.company] === "FEDEX"
                          ? "https://www.fedex.com/en-kr/home.html"
                          : CODE_SHIPPING_COMPANY[orderDetails?.company] ===
                            "UPS"
                          ? "https://www.ups.com/us/en/Home.page"
                          : CODE_SHIPPING_COMPANY[orderDetails?.company] ===
                            "EMS"
                          ? "https://ems.epost.go.kr/front.Tracking01Eng.postal"
                          : CODE_SHIPPING_COMPANY[orderDetails?.company] ===
                            "PACKET"
                          ? "https://ems.epost.go.kr/front.Tracking01Eng.postal"
                          : "/";
                      window.open(url, "_blank", "noopener, noreferrer");
                      return;
                    }}
                  >
                    Shipping
                  </div>
                ) : (
                  "Preparing for delivery"
                )}
              </MButton>
            </div>
          ))}
        </div>
      </MTabBody>
    ),
  };

  interface MobileViewProps {
    orderDetails: OrderDetails;
    openSections: { [key: string]: boolean };
    toggleSection: (section: string) => void;
  }

  const MobileView: React.FC<MobileViewProps> = ({
    orderDetails,
    openSections,
    toggleSection,
  }) => (
    <MWrapper>
      <MBody style={{ backgroundColor: "var(--color-lightgray)" }}>
        {Object.keys(openSections).map((section, index) => {
          if (
            (section === "PaymentKRW" && orderDetails.PaymentMethod !== 1) ||
            (section === "PaymentUSD" &&
              orderDetails.PaymentMethod !== 2 &&
              orderDetails.PaymentMethod !== 3)
          ) {
            return null;
          }

          return (
            <MTabSection key={index}>
              <MTabHeader onClick={() => toggleSection(section)}>
                <div className="dropdown">
                  {openSections[section] ? (
                    <Icon icon="caret-down-fill" />
                  ) : (
                    <Icon icon="caret-up" />
                  )}
                </div>

                <div> {section}</div>
              </MTabHeader>
              {openSections[section] && <>{sectionContents[section]}</>}
            </MTabSection>
          );
        })}
      </MBody>

      <MFooter style={{ padding: "1rem" }}>
        <div className="pay-dead">
          <div>Payment Deadline</div>
          <div className="date">
            {orderDetails.expiredAt ? orderDetails.expiredAt.slice(0, 10) : ""}
          </div>
        </div>
      </MFooter>
    </MWrapper>
  );

  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <MHeader closeButton>
          <div id="contained-modal-title-vcenter">Estimate - {orderNumber}</div>
        </MHeader>
        {isMobile ? (
          <MobileView
            orderDetails={orderDetails}
            openSections={openSections}
            toggleSection={toggleSection}
          />
        ) : (
          <>
            <Modal.Body>
              <Tabs
                activeKey={key}
                onSelect={handleSelect}
                className="mb-3 custom-tab"
              >
                <Tab eventKey="Request Information" title="Request Information">
                  <div className="request-info">
                    <div>
                      <strong className="h4 font-weight-bold">
                        <i
                          className="bi bi-chat-right-text-fill"
                          style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                        ></i>
                        Service Options
                      </strong>
                      <Row className="mt-3">
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionBox === 0
                                    ? "text-dark"
                                    : ""
                                }
                              >
                                Pack only one box
                              </span>
                            }
                            checked={orderDetails.optionBox === 0}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionLetter ? "text-dark" : ""
                                }
                              >
                                Letter bag box packaging
                              </span>
                            }
                            checked={orderDetails.optionLetter}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionPoster ? "text-dark" : ""
                                }
                              >
                                Minimize poster tubes
                              </span>
                            }
                            checked={orderDetails.optionPoster}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionAircap ? "text-dark" : ""
                                }
                              >
                                Add air cap
                              </span>
                            }
                            checked={orderDetails.optionAircap}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionSticker ? "text-dark" : ""
                                }
                              >
                                Stickers on 4 sides
                              </span>
                            }
                            checked={orderDetails.optionSticker}
                            onChange={() => {}}
                          />
                        </Col>
                        <Col md={6}>
                          <Form.Check
                            label={
                              <span
                                className={
                                  orderDetails.optionBag ? "text-dark" : ""
                                }
                              >
                                Insurance coverage
                              </span>
                            }
                            checked={orderDetails.optionBag}
                            onChange={() => {}}
                          />
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className="table-responsive mt-3">
                    <Table className="table-custom text-center">
                      <thead className="table-light">
                        <tr>
                          <th scope="col">NO</th>
                          <th scope="col" style={{ width: "25%" }}>
                            Tracking
                          </th>
                          <th scope="col">Costs</th>
                          <th scope="col">Extra</th>
                          <th scope="col">Required</th>
                          <th scope="col" style={{ width: "25%" }}>
                            Vacuum Packing
                          </th>
                          <th scope="col">BOX</th>
                        </tr>
                      </thead>
                      <tbody>
                        {orderDetails.products.map((product, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td
                              style={{
                                textDecoration: product.boxed
                                  ? "none"
                                  : "line-through",
                                width: "25%",
                              }}
                            >
                              {product.tracking}
                            </td>
                            <td>
                              ${product.overstoreFee} / ₩
                              {convertToKRW(product.overstoreFee)}
                            </td>
                            <td>
                              ${convertToUSD(product.Extra)} / ₩{product.Extra}
                            </td>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={product.isUrgent}
                                disabled
                              />
                            </td>
                            <td>
                              <Form.Check
                                type="checkbox"
                                checked={product.bag}
                                disabled
                              />
                            </td>
                            <td>{product.boxed}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Tab>
                <Tab
                  eventKey="Products not included"
                  title="Products Not Included"
                >
                  <div>
                    <strong className="h4 font-weight-bold">
                      <i
                        className="bi bi-chat-right-text-fill"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Service Options
                    </strong>
                    <Row className="align-items-center mt-3">
                      <Col md={6}>
                        <Form.Check
                          type="checkbox"
                          id="batteryCheck"
                          label="Suspected products including batteries"
                          checked={packingDetails.some(
                            (detail) => detail.suspectBattery
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <Form.Check
                          type="checkbox"
                          id="foodCheck"
                          label="Food"
                          checked={packingDetails.some((detail) => detail.food)}
                        />
                      </Col>
                    </Row>
                    <Row className="align-items-center mt-3">
                      <strong className="h4 font-weight-bold">
                        Packaging status:{" "}
                        {getPackingStatusText(orderDetails.packingStatus)}
                      </strong>
                    </Row>
                    <div className="table-responsive mt-3">
                      {excludedProducts.length > 0 && (
                        <Table className="table-custom text-center">
                          <thead className="table-light">
                            <tr>
                              <th>NO</th>
                              <th scope="col" style={{ width: "25%" }}>
                                Tracking
                              </th>
                              <th scope="col">Costs</th>
                              <th scope="col">Extra</th>
                              <th scope="col">Required</th>
                              <th scope="col" style={{ width: "25%" }}>
                                Vacuum Packing
                              </th>
                              <th scope="col">BOX</th>
                            </tr>
                          </thead>
                          <tbody>
                            {excludedProducts.map((product, index) => (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td
                                  style={{
                                    textDecoration: product.boxed
                                      ? "none"
                                      : "line-through",
                                    width: "25%",
                                  }}
                                >
                                  {product.tracking}
                                </td>
                                <td>
                                  ${product.overstoreFee} / ₩
                                  {convertToKRW(product.overstoreFee)}
                                </td>
                                <td>
                                  ${convertToUSD(product.Extra)} / ₩
                                  {product.Extra}
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    checked={product.isUrgent}
                                    disabled
                                  />
                                </td>
                                <td>
                                  <Form.Check
                                    type="checkbox"
                                    checked={product.bag}
                                    disabled
                                  />
                                </td>
                                <td>{"-"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      )}
                      {excludedProducts.length === 0 && (
                        <p>No products to display.</p>
                      )}
                    </div>
                  </div>
                </Tab>

                <Tab eventKey="주소" title="Address">
                  <div className="shipping-address">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi-map-fill"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Address
                    </strong>
                    <Row className="mb-3"></Row>
                    <Form>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formAddressReceiver">
                            <Form.Label className="font-bold">
                              Recipient
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              placeholder="받는 사람 이름"
                              defaultValue={orderDetails.adrReceiver}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formEmail">
                            <Form.Label className="font-bold">
                              E-mail
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrEmail}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formPhoneNumber">
                            <Form.Label className="font-bold">
                              Phone number
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrPhone}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formCountry">
                            <Form.Label className="font-bold">
                              Country
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              value={
                                CODE_COUNTRY_ITEMS[
                                  Number(orderDetails.adrCountry)
                                ] || "Country not selected"
                              }
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formPCC">
                            <Form.Label className="font-bold">PCC</Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrPCCC}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formZipcode">
                            <Form.Label className="font-bold">
                              Zipcode
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrZip}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formState">
                            <Form.Label className="font-bold">State</Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrState}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formCity">
                            <Form.Label className="font-bold">City</Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrCity}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formAddress1">
                            <Form.Label className="font-bold">
                              Address-1(35)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrOption1}
                            />
                          </Form.Group>
                        </Col>
                        <Col md={6}>
                          <Form.Group controlId="formAddress2">
                            <Form.Label className="font-bold">
                              Address-2(35)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrOption2}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col md={6}>
                          <Form.Group controlId="formAddress3">
                            <Form.Label className="font-bold">
                              Address-3(35)
                            </Form.Label>
                            <Form.Control
                              type="text"
                              readOnly
                              style={{ backgroundColor: "#f8f9fa" }}
                              defaultValue={orderDetails.adrOption3}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </Tab>

                <Tab eventKey="포장정보" title="Packaging Information">
                  <div className="packing-info">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi-box-seam-fill"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Packaging
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="table-responsive">
                      <Table bordered className="table-custom text-center">
                        <thead className="table-light">
                          <tr>
                            <th rowSpan={2} style={{ width: "15%" }}>
                              Package Picture
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Product ID
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Weight (KG)
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Width (CM)
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Length (CM)
                            </th>
                            <th rowSpan={2} style={{ width: "10%" }}>
                              Height (CM)
                            </th>
                            <th colSpan={2} style={{ width: "20%" }}>
                              Applied Weight
                            </th>
                          </tr>
                          <tr>
                            <th style={{ width: "10%" }}>FedEx, UPS</th>
                            <th style={{ width: "10%" }}>EMS, K-Packet</th>
                          </tr>
                        </thead>
                        <tbody>
                          {packingDetails
                            .filter((detail) => !detail.isDeleted)
                            .map((detail, index) => {
                              const volumeWeight5000 =
                                calculateVolumeWeight5000(
                                  detail.totalWidthCm,
                                  detail.totalLengthCm,
                                  detail.totalHeightCm
                                );
                              const volumeWeight6000 =
                                calculateVolumeWeight6000(
                                  detail.totalWidthCm,
                                  detail.totalLengthCm,
                                  detail.totalHeightCm
                                );

                              const roundedWeight5000 =
                                roundToFirstDecimal(volumeWeight5000);
                              const roundedWeight6000 =
                                roundToFirstDecimal(volumeWeight6000);

                              const displayWeight5000 = Math.max(
                                detail.totalWeightKg,
                                roundedWeight5000
                              );
                              const displayWeight6000 = Math.max(
                                detail.totalWeightKg,
                                roundedWeight6000
                              );

                              return (
                                <tr key={index}>
                                  <td>
                                    {detail.imageUrl ? (
                                      <img
                                        src={detail.imageUrl}
                                        alt="Attached File"
                                        style={{
                                          maxWidth: isZoomed
                                            ? "22rem"
                                            : "10rem",
                                          height: "auto",
                                          cursor: "pointer",
                                        }}
                                        onClick={() =>
                                          handleImageClick(detail.imageUrl)
                                        }
                                      />
                                    ) : (
                                      "No image available"
                                    )}
                                  </td>
                                  <td>{detail.id}</td>
                                  <td>{detail.totalWeightKg}</td>
                                  <td>{detail.totalWidthCm}</td>
                                  <td>{detail.totalLengthCm}</td>
                                  <td>{detail.totalHeightCm}</td>
                                  <td>{displayWeight5000}</td>
                                  <td>{displayWeight6000}</td>
                                </tr>
                              );
                            })}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Tab>
                <Tab eventKey="견적서" title="Estimate">
                  <div className="invoice-info">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi bi-calculator"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Estimate
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="summary">
                      <div className="table-responsive">
                        <Table className="table-custom text-center">
                          <thead className="table-light">
                            <tr>
                              <th>Delivery country</th>
                              <th>Request quantity</th>
                              <th>Quantity not Included</th>
                              <th>Reasons not Included</th>
                              <th>Box Quantity</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                {CODE_COUNTRY_ITEMS[
                                  Number(orderDetails.adrCountry)
                                ] || "Country not selected"}
                              </td>
                              <td>{orderDetails.products.length}</td>
                              <td>
                                {
                                  orderDetails.products.filter(
                                    (product) => !product.boxed
                                  ).length
                                }
                              </td>
                              <td>
                                {getPackingStatusText(
                                  orderDetails.packingStatus
                                )}
                              </td>
                              <td>
                                {
                                  packingDetails.filter(
                                    (detail) => !detail.isDeleted
                                  ).length
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* 포장비용 */}
                    <div className="repack-fee mt-4">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center font-unified"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={3} className="text-center">
                                Packaging Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Box quantity</td>
                              <td colSpan={2} className="text-center">
                                {
                                  packingDetails.filter(
                                    (detail) => !detail.isDeleted
                                  ).length
                                }
                              </td>
                            </tr>
                            <tr>
                              <td>Total weight</td>
                              <td colSpan={2} className="text-center">
                                {Number(totalWeightSum).toFixed(2)} KG
                              </td>
                            </tr>
                            <tr>
                              <td>Member's discount rate (%)</td>
                              <td colSpan={2} className="text-center">
                                {calculateMembershipDiscountRate()}%
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th>Total</th>
                              <td className="text-center">
                                KRW {repackFeeKRW}
                              </td>
                              <td className="text-center">
                                USD {repackFeeUSD}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    {/* 옵션비용 */}
                    <div className="option-fee">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center font-unified"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={3} className="text-center">
                                Optional Cost
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Sticker attachment</td>
                              <td colSpan={2} className="text-center">
                                {orderDetails.optionSticker
                                  ? `${packingDetails.length}ps(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Add air Cap</td>
                              <td colSpan={2} className="text-center">
                                {orderDetails.optionAircap
                                  ? `${packingDetails.length}ps(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Insurance</td>
                              <td colSpan={2} className="text-center">
                                {orderDetails.optionBag
                                  ? `${packingDetails.length}ps(s)`
                                  : "-"}
                              </td>
                            </tr>
                            <tr>
                              <td>Vacuum packaging bag</td>
                              <td colSpan={2} className="text-center">
                                {orderDetails.useVacuumPackagingBag
                                  ? `${orderDetails.useVacuumPackagingBag}ps(s)`
                                  : "-"}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th>Total</th>
                              <td className="text-center">
                                KRW {totalCostKRW.toLocaleString("ko-KR")}
                              </td>
                              <td className="text-center">
                                USD {totalCostUSD.toFixed(2)}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    {/* 웨어하우스 비용 */}
                    <div className="warehouse-fee">
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center"
                        >
                          <thead className="table-light">
                            <tr>
                              <th colSpan={3} className="text-center">
                                Warehouse Costs
                              </th>
                            </tr>
                            <tr>
                              <th className="text-center">Costs</th>
                              <th className="text-center">KRW</th>
                              <th className="text-center">USD</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="text-center">Warehouse Cost</td>
                              <td className="text-center">
                                ₩
                                {orderDetails.products
                                  .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) =>
                                      acc +
                                      Math.round(
                                        product.overstoreFee * 1000
                                      ),
                                    0
                                  )
                                  .toLocaleString("ko-KR")}
                              </td>
                              <td className="text-center">
                                $
                                {orderDetails.products
                                  .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) =>
                                      acc + product.overstoreFee,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                            <tr>
                              <td className="text-center">Extra charge</td>
                              <td className="text-center">
                                ₩
                                {orderDetails.products
                                  .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) => acc + product.Extra,
                                    0
                                  )
                                  .toLocaleString("ko-KR")}
                              </td>
                              <td className="text-center">
                                $
                                {orderDetails.products
                                  .filter((product) => product.boxed !== 0)
                                  .reduce(
                                    (acc, product) =>
                                      acc + product.Extra / exchangeRate,
                                    0
                                  )
                                  .toFixed(2)}
                              </td>
                            </tr>
                          </tbody>
                          <tfoot>
                            <tr className="table-primary">
                              <th className="text-center">Total</th>
                              <td className="text-center">
                                ₩{warehouseTotalKRW}
                              </td>
                              <td className="text-center">
                                ${warehouseTotalUSD}
                              </td>
                            </tr>
                          </tfoot>
                        </Table>
                      </div>
                    </div>
                    <div className="shipping-fee">
                      <h5 className="mb-3">Delivery charge</h5>
                      <div className="table-responsive">
                        <Table
                          bordered
                          hover
                          className="table-custom text-center"
                        >
                          <thead className="table-light">
                            <tr>
                              <th>Choice</th>
                              <th>Delivery company</th>
                              <th>KRW</th>
                              <th>USD</th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(shippingOptions)
                              .filter(
                                ([carrier]) =>
                                  carrier ===
                                  getShippingOptionLabel(orderDetails.company)
                              )
                              .map(([carrier, rates]) => (
                                <tr key={carrier}>
                                  <td>
                                    {checkedShippingOptions[carrier] ? (
                                      <CheckCircle
                                        onClick={() =>
                                          handleShippingOptionChange(carrier)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "#007bff",
                                          fontSize: "1.5rem",
                                        }}
                                      />
                                    ) : (
                                      <Check
                                        onClick={() =>
                                          handleShippingOptionChange(carrier)
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "#007bff",
                                          fontSize: "1.5rem",
                                        }}
                                      />
                                    )}
                                  </td>
                                  <td>{carrier}</td>
                                  <td>{rates.KRW?.toLocaleString() || "-"}</td>
                                  <td>
                                    {rates.USD ? rates.USD.toFixed(2) : "-"}
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                    {/* 고객 메시지 입력 폼 */}
                    <div className="manager-message mb-3">
                      <Card>
                        <Card.Header className="card-custom-header">
                          Message
                        </Card.Header>
                        <Card.Body>
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Thank you!"
                            defaultValue={orderDetails.customerMessage}
                            readOnly
                          />
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </Tab>

                {orderDetails.PaymentMethod === 1 && (
                  <Tab eventKey="KRW결제" title="KRW Payment">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi bi-credit-card"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Payment
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="krw-payment">
                      <Form>
                        <Row>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Name of depositor
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.depositor}
                                style={{ backgroundColor: "#f8f9fa" }}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Deposit amount(₩)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.DepositorFee}
                                readOnly
                                style={{ backgroundColor: "#f8f9fa" }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Points to Use(₩)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.usePointKrw}
                                readOnly
                                style={{ backgroundColor: "#f8f9fa" }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Coupon discount amount(₩)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.discountCouponKrw}
                                readOnly
                                style={{ backgroundColor: "#f8f9fa" }}
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={12}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Link
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.link}
                                readOnly
                                style={{ backgroundColor: "#f8f9fa" }}
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                        <div className="table-responsive">
                          <Table bordered className="table-custom text-center">
                            <tbody>
                              <tr>
                                <th
                                  style={{ width: "25%" }}
                                  className="font-bold"
                                >
                                  Delivery company
                                </th>
                                <td>
                                  {CODE_SHIPPING_COMPANY[
                                    orderDetails?.company
                                  ] || "Unallocated yet"}
                                </td>
                              </tr>
                              <tr>
                                <th
                                  style={{ width: "25%" }}
                                  className="font-bold"
                                >
                                  Bank
                                </th>
                                <td>Woori bank</td>
                              </tr>
                              <tr>
                                <th
                                  style={{ width: "25%" }}
                                  className="font-bold"
                                >
                                  account holder
                                </th>
                                <td>ONTACT KOREA</td>
                              </tr>
                              <tr>
                                <th
                                  style={{ width: "25%" }}
                                  className="font-bold"
                                >
                                  bank account number
                                </th>
                                <td>1005-804-179216</td>
                              </tr>
                              <tr>
                                <th
                                  style={{ width: "25%" }}
                                  className="font-bold"
                                >
                                  Deposit amount
                                </th>
                                <td>{orderDetails.DepositorFee}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Form>
                    </div>
                  </Tab>
                )}

                {(orderDetails.PaymentMethod === 2 ||
                  orderDetails.PaymentMethod === 3) && (
                  <Tab eventKey="USD결제" title="USD Payment">
                    <strong className="h4 font-weight-bold mb-3">
                      <i
                        className="bi bi-credit-card"
                        style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                      ></i>
                      Payment
                    </strong>
                    <Row className="mb-3"></Row>
                    <div className="krw-payment">
                      <Form>
                        <Row>
                          {/* <Col sm={6}>
                              <Form.Group className="mb-3">
                                <Form.Label className="font-bold">
                                  Name of depositor
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={orderDetails.depositor}
                                  readOnly
                                />
                              </Form.Group>
                            </Col> */}
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Deposit amount($)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.DepositorFee}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          {/* <Col sm={6}>
                              <Form.Group className="mb-3">
                                <Form.Label className="font-bold">
                                  Link
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  value={orderDetails.link}
                                  readOnly
                                />
                              </Form.Group>
                            </Col> */}
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Payment method
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={
                                  orderDetails.PaymentMethod === 2
                                    ? "PayPal"
                                    : orderDetails.PaymentMethod === 3
                                    ? "Other"
                                    : ""
                                }
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Points to Use($)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.usePointUse}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                          <Col sm={6}>
                            <Form.Group className="mb-3">
                              <Form.Label className="font-bold">
                                Coupon discount amount($)
                              </Form.Label>
                              <Form.Control
                                type="text"
                                value={orderDetails.discountCouponUsd}
                                readOnly
                              />
                            </Form.Group>
                          </Col>
                        </Row>
                      </Form>
                    </div>
                  </Tab>
                )}

                <Tab eventKey="Shipping" title="Shipping">
                  <strong className="h4 font-weight-bold mb-3">
                    <i
                      className="bi bi-truck"
                      style={{ fontSize: "1.5rem", marginRight: "0.5rem" }}
                    ></i>
                    Shipping
                  </strong>
                  <Row className="mb-3"></Row>
                  <div className="payment">
                    <Form>
                      <Table bordered className="table-custom text-center">
                        <thead className="table-light">
                          <tr>
                            <th>RP-NUMBER</th>
                            <th>TRACKING NUMBER</th>
                            <th>STATUS</th>
                          </tr>
                        </thead>
                        <tbody>
                          {activePackingDetails.map((detail, index) => (
                            <tr key={index}>
                              <td>{detail.id}</td>
                              <td>{detail.express || "Not entered"}</td>
                              <td>
                                {detail.express ? (
                                  <a
                                    href={
                                      CODE_SHIPPING_COMPANY[
                                        orderDetails?.company
                                      ] === "FEDEX"
                                        ? "https://www.fedex.com/en-kr/home.html"
                                        : CODE_SHIPPING_COMPANY[
                                            orderDetails?.company
                                          ] === "UPS"
                                        ? "https://www.ups.com/us/en/Home.page"
                                        : CODE_SHIPPING_COMPANY[
                                            orderDetails?.company
                                          ] === "EMS"
                                        ? "https://www.epost.go.kr/main/eng/Enpost_Introduction1.html"
                                        : CODE_SHIPPING_COMPANY[
                                            orderDetails?.company
                                          ] === "PACKET"
                                        ? "https://www.epost.go.kr/main/eng/Enpost_Introduction1.html"
                                        : "/"
                                    }
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    shipping
                                  </a>
                                ) : (
                                  "Preparing for delivery"
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Form>
                  </div>
                </Tab>
              </Tabs>
            </Modal.Body>
            <Modal.Footer>
              <div className="w-100 mb-3">
                <InputGroup className="mb-3 input-group">
                  <InputGroup.Text className="input-group-text-custom">
                    Payment Deadline
                  </InputGroup.Text>
                  <Form.Control
                    defaultValue={
                      orderDetails.expiredAt
                        ? orderDetails.expiredAt.slice(0, 10)
                        : ""
                    }
                    readOnly
                  />
                </InputGroup>
              </div>
            </Modal.Footer>
          </>
        )}
      </Modal>

      <Modal show={showImageModal} onHide={closeImageModal} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img
            src={imageToShow}
            alt="Enlarged"
            style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

const PriceRowTable = styled(Table)`
  text-align: center;
`;

const EstimateTableSection = styled.div`
  .shade {
    background-color: var(--color-lightgray);
  }
`;
